import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./adminStyles.css";

const Admin = () => {
  const [offer, setOffer] = useState({
    link: "",
    text1: "",
    text2: "",
    percentagePerDay: "",
    ageRange: "",
    imageUrl: "",
    reklamaTitle: "",
  });

  const [botOffer, setBotOffer] = useState({
    link: "",
    imageUrl: "",
    amount: "",
    dailyRate: "",
    borrowerAge: "",
    reklamaTitle: "",
    betTitle: "",
  });

  const handleSaveBotOffer = async () => {
    try {
      // Создайте объект для данных Bot Offer
      const botOfferData = {
        link: botOffer.link,
        imageUrl: botOffer.imageUrl,
        amount: botOffer.amount,
        dailyRate: botOffer.dailyRate,
        borrowerAge: botOffer.borrowerAge,
        reklamaTitle: botOffer.reklamaTitle,
        betTitle: botOffer.betTitle,
      };

      // Отправляем данные Bot Offer на сервер
      await axios.post(
        `${process.env.REACT_APP_BACK_URL}api/saveBotOffer`,
        botOfferData
      );

      // Опционально: очищаем поля формы после успешного сохранения
      setBotOffer({
        link: "",
        imageUrl: "",
        amount: "",
        dailyRate: "",
        borrowerAge: "",
        reklamaTitle: "",
        betTitle: "",
      });

      // Дополнительная логика после сохранения данных, если необходимо
    } catch (error) {
      console.error("Ошибка при сохранении Bot Offer", error);
    }
  };

  const handleSaveOffer = async () => {
    try {
      // Отправляем данные предложения на сервер
      await axios.post(`${process.env.REACT_APP_BACK_URL}api/saveOffer`, offer);

      // Опционально: очищаем поля формы после успешного сохранения
      setOffer({
        link: "",
        text1: "",
        text2: "",
        percentagePerDay: "",
        ageRange: "",
        imageUrl: "",
        reklamaTitle: "",
      });

      // Дополнительная логика после сохранения данных, если необходимо
    } catch (error) {
      console.error("Ошибка при сохранении данных", error);
    }
  };

  return (
    <div style={{ padding: "15px" }}>
      <Link to={"/g05jgd62478"}>
        <button style={{ backgroundColor: "red" }} className="buttonUni">
          Офферы
        </button>
      </Link>
      <hr />
      <h3 className="mb-4">Top Offers</h3>
      <div className="mb-4">
        <div className="inputUni-container">
          <span>Ссылка:</span>
          <input
            type="text"
            value={offer.link}
            placeholder="https://gl.guruleads.ru/click/23092/238?erid=LjN8Jz6Mo&sub1=mail"
            onChange={(e) =>
              setOffer((prevOffer) => ({ ...prevOffer, link: e.target.value }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Текст 1 - Займы под 0%:</span>
          <input
            type="text"
            value={offer.text1}
            placeholder="Займы под 0%"
            onChange={(e) =>
              setOffer((prevOffer) => ({ ...prevOffer, text1: e.target.value }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Текст 2 - До 30 000 рублей:</span>
          <input
            type="text"
            value={offer.text2}
            placeholder="30 000
            "
            onChange={(e) =>
              setOffer((prevOffer) => ({ ...prevOffer, text2: e.target.value }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>от X% в день:</span>
          <input
            type="text"
            value={offer.percentagePerDay}
            placeholder="от 0% в день"
            onChange={(e) =>
              setOffer((prevOffer) => ({
                ...prevOffer,
                percentagePerDay: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>от X до X лет:</span>
          <input
            type="text"
            value={offer.ageRange}
            placeholder="от 20 до 70 лет"
            onChange={(e) =>
              setOffer((prevOffer) => ({
                ...prevOffer,
                ageRange: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>URL картинки:</span>
          <input
            type="text"
            value={offer.imageUrl}
            placeholder="https://drive.google.com/file/d/1BSQlf6jMw72p7aT4llrz2azhTr252SQt/view?usp=sharing"
            onChange={(e) =>
              setOffer((prevOffer) => ({
                ...prevOffer,
                imageUrl: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
      </div>
      <button onClick={handleSaveOffer} className="buttonUni">
        Создать Top Offer
      </button>

      <h3 className="mb-4">Bot Offers</h3>
      <div className="mb-4">
        <div className="inputUni-container">
          <span>Ссылка:</span>
          <input
            placeholder="https://gl.guruleads.ru/click/23092/1057?erid=LjN8KVai4&sub1=mail"
            type="text"
            value={botOffer.link}
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                link: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>URL картинки:</span>
          <input
            placeholder="https://lh3.googleusercontent.com/drive-viewer/AEYmBYRLY3zkGD9ZiO-CIQttmKlhQJMw60N2qsiuoUN1XMGBg-xIY519xhIhGv5-ApuKlPwRpdgAUXkEPRap7uy3_zuWsKzx0w=w1920-h912"
            type="text"
            value={botOffer.imageUrl}
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                imageUrl: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Сумма:</span>
          <input
            type="text"
            value={botOffer.amount}
            placeholder="30 000₽"
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                amount: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Ставка в день:</span>
          <input
            type="text"
            value={botOffer.dailyRate}
            placeholder="от 0% в день"
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                dailyRate: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Возраст заемщика:</span>
          <input
            type="text"
            value={botOffer.borrowerAge}
            placeholder="18-75лет"
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                borrowerAge: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Заголовок 1</span>
          <input
            type="text"
            value={botOffer.betTitle}
            placeholder="98% одобрение"
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                betTitle: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <div className="inputUni-container">
          <span>Маленький текст</span>
          <input
            type="text"
            value={botOffer.reklamaTitle}
            placeholder="Реклама.ООО МФК «Мани Мен» ИНН 7704784072"
            onChange={(e) =>
              setBotOffer((prevBotOffer) => ({
                ...prevBotOffer,
                reklamaTitle: e.target.value,
              }))
            }
            className="inputUni-control"
          />
        </div>
        <button onClick={handleSaveBotOffer} className="buttonUni">
          Создать Bot Offer
        </button>
      </div>
    </div>
  );
};

export default Admin;
