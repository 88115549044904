import React, { useEffect, useState } from "react";
import "./loansTable.style.css";
import { loanData } from "../../data/loansData";
import TableItem from "./TableItem/tableItem.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function LoansTable() {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [botOffers, setbotOffers] = useState([]);

  useEffect(() => {
    // Выполняем GET-запрос к серверу для получения Top Offers
    const fetchBotOffers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_URL}api/getBotOffers`
        );
        setbotOffers(response.data.botOffers);
        console.log(response.data.offers, "ssssssssssssssssfff");
      } catch (error) {
        console.error("Ошибка при получении Top Offers", error);
      }
    };

    fetchBotOffers();
  }, []);

  const handleSort = (criteria) => {
    if (sortBy === criteria) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(criteria);
      setSortOrder("asc");
    }
  };

  const sortedData = () => {
    if (sortBy) {
      return botOffers.slice().sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (sortOrder === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });
    } else {
      return botOffers;
    }
  };

  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === "asc" ? (
        <FontAwesomeIcon icon={faArrowUpShortWide} />
      ) : (
        <FontAwesomeIcon icon={faArrowDownShortWide} />
      );
    }
    return null;
  };

  return (
    <div className="loans_table" style={{ paddingTop: "0px" }}>
      <p
        style={{
          textAlign: "center",
          fontStyle: "italic",
          fontSize: "1.2rem",
          marginTop: "0px",
        }}
      >
        Для гарантированного получения денег рекомендуем отправить 2 или более
        заявок
      </p>
      <div className="table_tabs">
        <div className="table_tab">
          <p className="tab_name">МФО</p>
        </div>
        <div className="table_tab" onClick={() => handleSort("sum")}>
          <p className="tab_name">Сумма {renderSortIcon("sum")}</p>
        </div>
        <div className="table_tab" onClick={() => handleSort("inDay")}>
          <p className="tab_name">Ставка в день {renderSortIcon("inDay")}</p>
        </div>
        <div className="table_tab" onClick={() => handleSort("maxAge")}>
          <p className="tab_name">
            Возраст заемщика {renderSortIcon("maxAge")}
          </p>
        </div>
        <div className="table_tab">
          <p className="tab_name">Заявки</p>
        </div>
      </div>
      {sortedData().map((item) => {
        return <TableItem data={item} />;
      })}
    </div>
  );
}
