export const orgData = [
  {
    id: 1,
    title: "ООО «МигКредит»",
    address: "127018, г. Москва, ул. Сущевский Вал, д. 5, стр. 3",
    phone: "8 (800) 100 06 09",
    email: "contact@migcredit.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 2110177000037,
    licenseDate: "08.07.2011",
  },
  {
    id: 2,
    title: "ООО МФК «Займер»",
    address: "630099, Новосибирск, ул. Октябрьская Магистраль, дом 3, оф. 906",
    phone: "8 (800) 7070-24-7",
    email: "support@zaymer.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651303532004088,
    licenseDate: "11.10.2013",
  },
  {
    id: 3,
    title: "ООО МКК «Фастмани.ру»",
    address: "630099, Новосибирск, ул. Октябрьская Магистраль, дом 3, оф. 906",
    phone: "8 (800) 333-22-00",
    email: "info@fastmoney.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 1803140008707,
    licenseDate: "18.01.2018",
  },
  {
    id: 4,
    title: "ООО МФК «Мани Мен»",
    address: "121096, Москва, ул. Василисы Кожиной, д.1, офис Д13",
    phone: "+7 (499) 681-83-83",
    email: "info@moneyman.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 2110177000478,
    licenseDate: "25.10.2011",
  },
  {
    id: 5,
    title: "ООО МФК «Экофинанс»",
    address: "127051, г. Москва, ул. Садовая-Самотёчная, дом 24/27",
    phone: "8 (800)-222-23-23",
    email: "info@creditplus.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651503045006452,
    licenseDate: "13.05.2015",
  },
  {
    id: 6,
    title: "ООО МКК Платиза.ру",
    address: "117105, г. Москва, ш. Варшавское, д. 1, стр. 6, комн. 2",
    phone: "8 (800) 200 38 39",
    email: "support@platiza.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651203045001237,
    licenseDate: "12.03.2012",
  },
  {
    id: 7,
    title: "ООО МФК Турбозайм",
    address: "123290, г. Москва, тупик Магистральный 1-й, д. 11, стр.10",
    phone: "8 (499) 951-91-80",
    email: "info@turbozaim.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651303045003951,
    licenseDate: "29.08.2013",
  },
  {
    id: 8,
    title: "ООО МФК «Е заем»",
    address:
      "115114, Москва, ул. Летниковская, д. 10, стр. 4, 2 этаж, помещение I",
    phone: "8 (800) 600-55-00",
    email: "info@ezaem.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651303045003161,
    licenseDate: "14.05.2013",
  },
  {
    id: 9,
    title: "ООО МФК ВЭББАНКИР",
    address: "125466, г. Москва, ул. Соколово-Мещерская, д. 29, оф. 308",
    phone: "8 (800) 775-54-54",
    email: "info@webbankir.com",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 2120177002077,
    licenseDate: "11.09.2012",
  },
  {
    id: 10,
    title: "ООО МФК «Лайм-Займ»",
    address: "630102, Новосибирск, ул. Кирова 48, оф. 1401",
    phone: " +7 (383) 207-98-89",
    email: "support@lime-zaim.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651303045004102,
    licenseDate: "17.10.2013",
  },
  {
    id: 11,
    title: "BigZaim",
    address: "656002, г. Барнаул, пр-т Комсомольский, 128Б",
    phone: "8 (3852) 59-02-01",
    email: "client@big-zaim.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 318222500070662,
    licenseDate: "12.11.2018",
  },
  {
    id: 12,
    title: "ООО МКФ Джой Мани",
    address: "630099, г. Новосибирск, ул. Максима Горького, д.14",
    phone: "8 (800) 707-22-43",
    email: "client@big-zaim.ru",
    percent: 365,
    termFrom: 65,
    termTo: 365,
    license: 651403550005450,
    licenseDate: "28.07.2014",
  },
];
