import React from 'react'
import './footer.style.css'
import soglasie from '../../assets/soglasie.pdf'
export default function Footer() {
    return (
        <div className='footer'>
            <p className='footer_text'>Данный сайт не является кредитной организацией или ее представительством и не осуществляет выдачу займов и кредитов. Сайт предоставляет
                актуальную информацию по выбору кредитных предложений на территории Российской Федерации. Все представленные на сайте кредитные
                учреждения имеют соответствующие лицензии и находятся в реестре Центрального банка РФ.
                Указанные на сайте предложения не являются офертой. Конечные условия займов, а также последствия их неуплаты Вы можете уточнить на сайте
                соответствующей кредитной организации.</p>
            <p className='footer_text'>
                ООО "Тиронд" ОГРН 1197547090278
            </p>
            <p className='footer_text'>117452, г. Москва, бульвар Черноморский, дом 17, корпус 1</p>
            <a className='footer_privacy' target='__blank' href={soglasie}>Политика конфиденциальности</a>
        </div>
    )
}
