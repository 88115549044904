import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    const expectedLogin = "HriberYouSkywarydERM";
    const expectedPassword = "]{536nAPD4h70zv&7Uyg";
    const yourToken =
      "gJRm6z52MxfxpvucVdaYqayyYdj2S80ESZ0G7AZkC7BJOjC7L6IkbqwyUdmH55Ug";

    if (login === expectedLogin && password === expectedPassword) {
      localStorage.setItem("token", yourToken);
      navigate("/");
    } else {
      console.log("Invalid login or password");
    }
  };

  return (
    <div className="container d-flex align-items-center justify-content-center vh-100">
      <div className="card p-3" style={{ width: "300px" }}>
        <h5>Вход</h5>
        <input
          type="password"
          className="form-control mb-3"
          style={{ padding: "8px", boxSizing: "border-box", fontSize: "14px" }}
        />
        <button
          // onClick={handleLogin}
          className="btn btn-primary"
          style={{ fontSize: "16px" }}
        >
          Войти
        </button>
      </div>
    </div>
  );
};

export default Login;
