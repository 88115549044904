import React, { useEffect, useState } from "react";
import "./cards.style.css";
import { cards } from "../../data/cardsData";
import Card from "./Card/card.component";
import axios from "axios";
export default function Cards() {
  const [topOffers, setTopOffers] = useState([]);

  useEffect(() => {
    // Выполняем GET-запрос к серверу для получения Top Offers
    const fetchTopOffers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_URL}api/getOffers`
        );
        setTopOffers(response.data.offers);
      } catch (error) {
        console.error("Ошибка при получении Top Offers", error);
      }
    };

    fetchTopOffers();
  }, []);

  return (
    <div className="cards">
      {topOffers?.map((el) => {
        return <Card data={el} />;
      })}
    </div>
  );
}
