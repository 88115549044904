import React from "react";
import "../cards.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCoins,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";

const linkStyles = {
  textDecoration: "none",
};

export default function Card({ data }) {
  return (
    <div className="card">
      <a style={linkStyles} href={data.link}>
        <div className="card_logo_container">
          <img src={data.imageUrl} className="card_logo" alt="Card Logo" />
        </div>
        <div className="card_title_container">
          <p className="card_title">{data.text1}</p>
        </div>
        <div className="card_texts_container">
          <h1 className="card_up_to">
            <FontAwesomeIcon icon={faPercent} className="card_icon" />
            До {data.text2
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
            <br /> рублей
          </h1>
          <p className="card_from">
            <FontAwesomeIcon icon={faCoins} className="card_icon" />{" "}
            {data.percentagePerDay}
          </p>
          <p className="card_from">
            <FontAwesomeIcon icon={faCalendarCheck} className="card_icon" />
            {data.ageRange}
          </p>
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <button className="card_btn">Получить деньги</button>
          </a>
          <p className="card_tin">{data.reklamaTitle}</p>
        </div>
      </a>
    </div>
  );
}
