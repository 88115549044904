import React from "react";
import "./tableItem.style.css";

export default function TableItem({ data }) {
  return (
    <>
      <a style={{ textDecoration: "none" }} href={data.link}>
        <div className="table_item">
          <div className="table_row">
            <img src={data.imageUrl} className="row_mfo" />
          </div>
          <div className="table_row">
            <p className="row_sum">{data.amount}₽</p>
          </div>
          <div className="table_row">
            <p className="row_inday">{data.dailyRate}</p>
            <div className="row_badge">
              <p className="badge_text">{data.betTitle}</p>
            </div>
          </div>
          <div className="table_row">
            <p className="row_age">{data.borrowerAge}</p>
          </div>
          <div className="table_row">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <a href={data.link} target="__blank">
                <button className="row_btn">Получить деньги</button>
              </a>
              <p className="row_advertising">{data.reklamaTitle}</p>
            </div>
          </div>
        </div>
      </a>
      <div className="table_item_mb">
        <a style={{ textDecoration: "none" }} href={data.link}>
          <div className="table_mfo">
            <img src={data.imageUrl} className="row_mfo_mb" />
            <div className="row_badge_mb">
              <p className="badge_text">{data.betTitle}</p>
            </div>
          </div>
          <div className="table_info_mb">
            <div className="row_info_cont">
              <div className="table_info_cont">
                <p className="info_label">Сумма</p>
                <p className="info_data">{data.amount}₽</p>
              </div>
              <div className="table_info_cont">
                <p className="info_label">Ставка в день</p>
                <p className="info_data">{data.dailyRate}</p>
              </div>
              <div className="table_info_cont">
                <p className="info_label">Возраст</p>
                <p className="info_data">{data.borrowerAge}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "3em",
              }}
            >
              <a href={data.link}>
                <button className="row_btn">Получить деньги</button>
              </a>
              <p className="row_advertising">{data.reklamaTitle}</p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
