import Banner from "../components/Banner/banner.component";
import Cards from "../components/Cards/cards.component";
import FaqAccordion from "../components/FaqAccordion/faqAccordion.component";
import FaqTabs from "../components/FaqTabs/faqTabs.component";
import Footer from "../components/Footer/footer.component";
import LoansTable from "../components/LoansTable/loansTable.component";
import Modal from "../components/Notice/notice.component";

export const Main = () => {
  return (
    <>
      <Banner />
      <Cards />
      <LoansTable />
      <FaqTabs />
      <FaqAccordion />
      <Footer />
      {/* <Modal /> */}
    </>
  );
};
