import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Offers = () => {
  const [offers, setOffers] = useState([]);
  const [botOffers, setBotOffers] = useState([]);

  useEffect(() => {
    // Получаем данные при загрузке компонента
    const fetchData = async () => {
      try {
        const responseOffers = await axios.get(
          `${process.env.REACT_APP_BACK_URL}api/getOffers`
        );
        setOffers(responseOffers.data.offers);

        const responseBotOffers = await axios.get(
          `${process.env.REACT_APP_BACK_URL}api/getBotOffers`
        );
        setBotOffers(responseBotOffers.data.botOffers);
      } catch (error) {
        console.error("Ошибка при получении данных", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mt-5">
      {" "}
      <Link to={"/g05adb62456"}>
        <button className="btn btn-danger mb-2">Назад</button>
      </Link>
      <hr />
      <div>
        <h3>Top Offers</h3>
        <ul>
          {offers.map((offer) => (
            <li key={offer._id}>
              {/* Ваш код для отображения данных о предложении */}
            </li>
          ))}
        </ul>

        <h3>Bot Offers</h3>
        <ul>
          {botOffers.map((botOffer) => (
            <li key={botOffer._id}>
              {/* Ваш код для отображения данных о Bot Offer'е */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Offers;
